import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import HeaderForm from "../../util/HeaderForm";
import {InputForm, SelectForm} from "../../util/HelpersForm";
import {addItemLot, updateItemLot} from "../../../redux/actions/logistic";


const FormItemLot = ({data, onClose, category, lot_id, lot}) => {
    const dispatch = useDispatch();
    const pallets = useSelector(state => state.Logistic.pallets);

    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            form['lot'] = lot_id;
            data ? dispatch(updateItemLot(data?.id, form, lot)) : dispatch(addItemLot(form, lot));
            onClose();
        }
    })

    return (

        <form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
            <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
            <div className={`grid  grid-cols-2 gap-2`}>

                <InputForm values={formik.values.number} errors={formik.errors.number} formik={formik} field={'number'}
                           title={'Número'}/>
                <InputForm values={formik.values.weight} errors={formik.errors.weight} formik={formik} field={'weight'}
                           title={'Peso'}/>
                <InputForm values={formik.values.box_0} errors={formik.errors.box_0} formik={formik}
                           field={'box_0'}  max_length={2}
                           title={'Costal'}/>
                <InputForm values={formik.values.box_0_25} errors={formik.errors.box_0_25} formik={formik}
                           field={'box_0_25'}  max_length={2}
                           title={'Jaba 0.25'}/>
                <InputForm values={formik.values.box_0_5} errors={formik.errors.box_0_5} formik={formik}
                           field={'box_0_5'}  max_length={2}
                           title={'Jaba 0.5'}/>
                <InputForm values={formik.values.box_1} errors={formik.errors.box_1} formik={formik} field={'box_1'}
                            max_length={2}
                           title={'Jaba 1'}/>
                <InputForm values={formik.values.box_1_5} errors={formik.errors.box_1_5} formik={formik}
                           field={'box_1_5'} max_length={2}
                           title={'Jaba 1.5'}/>
                <InputForm values={formik.values.box_1_6} errors={formik.errors.box_1_6} formik={formik}
                           field={'box_1_6'} max_length={2}
                           title={'Jaba 1.6'}/>
                <InputForm values={formik.values.box_greenbox} errors={formik.errors.box_greenbox} formik={formik}
                           field={'box_greenbox'} max_length={2}
                           title={'Jaba Greenbox'}/>
                <SelectForm values={formik.values.pallet} errors={formik.errors.pallet} formik={formik} field={'pallet'}
                            display={'name'} class_={'col-span-2'}
                            title={'Pallet'} options={pallets}/>
                {category === 'Piña' && <>
                    <InputForm values={formik.values.c6} errors={formik.errors.c6} formik={formik} field={'c6'}  max_length={2}
                               title={'C6'}/>
                    <InputForm values={formik.values.c8} errors={formik.errors.c8} formik={formik} field={'c8'}  max_length={2}
                               title={'C8'}/>
                    <InputForm values={formik.values.c10} errors={formik.errors.c10} formik={formik} field={'c10'}  max_length={2}
                               title={'C10'}/>
                    <InputForm values={formik.values.c12} errors={formik.errors.c12} formik={formik} field={'c12'} max_length={2}
                               title={'C12'}/>
                    <InputForm values={formik.values.c14} errors={formik.errors.c14} formik={formik} field={'c14'} max_length={2}
                               title={'C14'}/>
                </>

                }


            </div>
        </form>

    );
};

const initialValues = (data) => {
    return {

        number: data?.number || '',
        weight: data?.weight || 0,
        box_0: data?.box_0 || 0,
        box_0_25: data?.box_0_25 || 0,
        box_0_5: data?.box_0_5 || 0,
        box_1: data?.box_1 || 0,
        box_1_5: data?.box_1_5 || 0,
        box_1_6: data?.box_1_6 || 0,
        box_greenbox: data?.box_greenbox || 0,
        pallet: data?.pallet || '',
        c6: data?.c6 || 0,
        c8: data?.c8 || 0,
        c10: data?.c10 || 0,
        c12: data?.c12 || 0,
        c14: data?.c14 || 0,


    }
}
const newSchema = () => {
    return {
        number: Yup.number().integer().positive().required(),
        weight: Yup.number().positive().required(),
        box_0: Yup.number().integer().min(0).required(),
        box_0_25: Yup.number().integer().min(0).required(),
        box_0_5: Yup.number().integer().min(0).required(),
        box_1: Yup.number().integer().min(0).required(),
        box_1_5: Yup.number().integer().min(0).required(),
        box_1_6: Yup.number().integer().min(0).required(),
        pallet: Yup.string().min(6).required(),
        c6: Yup.number().integer().min(0).required(),
        c8: Yup.number().integer().min(0).required(),
        c10: Yup.number().integer().min(0).required(),
        c12: Yup.number().integer().min(0).required(),
        c14: Yup.number().integer().min(0).required(),

    }
}

export default FormItemLot;
