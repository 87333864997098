import React from 'react';

const TableBase = ({columns, cell}) => {
    return (<table className="bg-white w-full ">
        <thead className="h-10 sticky top-0 bg-white ">
        <tr>
            {columns && columns.map((column, index) => (<th key={index}
                                                            className="text-gray-400 sticky font-medium p-6 text-sm text-start whitespace-nowrap border-b-2">
                {column}
            </th>))}
        </tr>
        </thead>
        <tbody className={"p-4 "}>
        {cell ? cell : (<tr className="border-gray-300 border-b ">
            {columns && columns.map((column, index) => {
                return (<td key={index} className="pb-4 px-6 text-start ">
                    <div className="h-8 bg-gray-300 rounded animate-pulse mt-2"></div>
                    <div className="h-2 bg-gray-300 rounded animate-pulse mt-2"></div>
                    <div className="h-2 bg-gray-300 rounded animate-pulse mt-2"></div>
                    <div className="h-2 bg-gray-300 rounded animate-pulse mt-2"></div>
                    <div className="h-2 bg-gray-300 rounded animate-pulse mt-2"></div>
                    <div className="h-2 bg-gray-300 rounded animate-pulse mt-2"></div>
                    <div className="h-2 bg-gray-300 rounded animate-pulse mt-2"></div>
                    <div className="h-2 bg-gray-300 rounded animate-pulse mt-2"></div>
                    <div className="h-2 bg-gray-300 rounded animate-pulse mt-2"></div>
                    <div className="h-2 bg-gray-300 rounded animate-pulse mt-2"></div>
                    <div className="h-2 bg-gray-300 rounded animate-pulse mt-2"></div>
                    <div className="h-2 bg-gray-300 rounded animate-pulse mt-2"></div>
                    <div className="h-2 bg-gray-300 rounded animate-pulse mt-2"></div>
                    <div className="h-2 bg-gray-300 rounded animate-pulse mt-2"></div>


                </td>);
            })}


        </tr>)}
        </tbody>
    </table>);
};

const Cell = ({text, handler, classes,wrap}) => {
    return (<td onClick={handler}
                className={`text-sm  ${wrap?'whitespace-wrap':'whitespace-nowrap'} text-gray-800 font-light text-start px-6 py-2 ${classes}  `}>
        {text}
    </td>);
};

export {TableBase as default, Cell};
