import React, {useEffect} from 'react';
import Layout from "../../../../hocs/Layout";
import TableLot from "../../../../components/e2e/Logistic/TableLot";
import AsideLot from "../../../../components/e2e/Logistic/AsideLot";
import CardsLot from "../../../../components/e2e/Logistic/CardsLot";
import Badge from "../../../../components/e2e/Logistic/Badge";
import Plugins from "../../../../components/e2e/Logistic/Plugins";
import ButtonHelper from "../../../../components/util/ButtonHelper";
import {Helmet} from "react-helmet";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {deleteDocument, deleteItemLot, getDocumentLot, getLot, getPallets} from "../../../../redux/actions/logistic";
import {get_categories} from "../../../../redux/actions/category";
import {
    getManufacturingCompanies,
    getRawMaterialSuppliers,
    getTransportationCompanies
} from "../../../../redux/actions/stakeholders";
import ReportViewer from "../../../../components/e2e/Logistic/Report";
import ModalHook from "../../../../hooks/ModalHook";
import Modal from "../../../../components/util/Modal";
import HeaderForm from "../../../../components/util/HeaderForm";
import LabelViewer from "../../../../components/e2e/Logistic/Label";
import RegisterViewer from "../../../../components/e2e/Logistic/Register";
import {MySwal} from "../../../../components/util/helpers";
import FormItemLot from "../../../../components/e2e/Logistic/FormItemLot";
import {
    deleteThumbnail,
    getCPineappleLot,
    getCSweetPotatoLot,
    getThumbnailLot
} from "../../../../redux/actions/production";
import UploadDocument from "../../../../components/e2e/Logistic/FormDocumentLot";
import PhotoUploadForm from "../../../../components/e2e/Logistic/FormUploadPhoto";
import ReportProcessPineapple from "../../../../components/e2e/Logistic/ReportProcessPineapple";
import ReportProcessSweetPotato from "../../../../components/e2e/Logistic/ReportProcessSweetPotato";
import FormProductionDiscount from "../../../../components/e2e/Logistic/FormProductionDiscount";
import {getAnalysisLot} from "../../../../redux/actions/quality";

const Lot = () => {
    const {lot} = useParams()
    const dispatch = useDispatch();
    const data = useSelector(state => state.Logistic.lot);
    const categories = useSelector(state => state.Category.categories);
    const items = useSelector(state => state.Logistic.items);
    const thumbnail = useSelector(state => state.Production.thumbnail);
    const files = useSelector(state => state.Logistic.document);



    const {content, setContent, isOpen, openModal} = ModalHook();
    const product = categories && categories.find(e => e.name === 'Materia Prima')?.children.find(e => e.id === data?.product)?.name

    useEffect(() => {
        dispatch(getLot(lot))
        dispatch(getDocumentLot(lot))
        if (product === 'Piña') {
            dispatch(getCPineappleLot(lot))
            dispatch(getAnalysisLot('Piña', lot))
        }
        if (product === 'Camote') {
            dispatch(getCSweetPotatoLot(lot))
            dispatch(getAnalysisLot('Camote', lot))
        }
        dispatch(get_categories())
        dispatch(getRawMaterialSuppliers())
        dispatch(getTransportationCompanies())
        dispatch(getManufacturingCompanies())
        dispatch(getPallets())
        dispatch(getThumbnailLot(lot))
    }, []);

    const handleViewReport = () => {
        setContent(<div className={"h-screen"}>
            <HeaderForm close={openModal}/>
            <ReportViewer category={product}  data={data}/></div>);
        openModal();
    }
    const handleViewLabel = () => {
        setContent(<div className={"h-screen"}>
            <HeaderForm close={openModal}/>
            <LabelViewer data={data} category={product}/></div>);
        openModal();
    }
    const handleViewInfo = () => {
        setContent(<div className={"h-screen"}>
            <HeaderForm close={openModal}/>
            <RegisterViewer data={items} info={data} category={product}/></div>);
        openModal();
    }
    const handleViewProcess = () => {
        setContent(<div className={"h-screen"}>
            <HeaderForm close={openModal}/>
            {product === 'Piña' &&
                <ReportProcessPineapple items={items ? items : []} photos={thumbnail} data={data} category={product}/>}
            {product === 'Camote' && <ReportProcessSweetPotato photos={thumbnail} data={data} category={product}/>}
        </div>);
        openModal();
    }

    const handleAdd = () => {
        const formComponent = <FormItemLot lot={lot} lot_id={data?.id} category={product} onClose={openModal}/>;
        setContent(formComponent);
        openModal();
    };
    const handleAddPhotoProcess = () => {
        const formComponent = <PhotoUploadForm lot={lot} lot_id={data?.id} onClose={openModal}/>;
        setContent(formComponent);
        openModal();
    };


    const handleAddFile = () => {
        const formComponent = <UploadDocument lot={lot} lot_id={data?.id} onClose={openModal}/>;
        setContent(formComponent);
        openModal();
    };

    const handleUpdate = (values) => {
        const formComponent = <FormItemLot lot={lot} lot_id={data?.id} category={product} onClose={openModal}
                                           data={values}/>;
        setContent(formComponent);
        openModal();
    };

    const handleUpdateProduction = () => {
        const formComponent = <FormProductionDiscount lot={lot} lot_id={data?.id} onClose={openModal}
                                                      data={data}/>;
        setContent(formComponent);
        openModal();
    };

    const handleDelete = (value) => {
        MySwal.fire({
            title: '¿Desea eliminar este registro?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7DABF5',
            confirmButtonColor: '#F87171',
            confirmButtonText: 'Eliminar',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteItemLot(value, lot))
            }
        });
    };

    const handleDeleteThumbnail = (value) => {
        MySwal.fire({
            title: '¿Desea eliminar esta fotografia?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7DABF5',
            confirmButtonColor: '#F87171',
            confirmButtonText: 'Eliminar',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteThumbnail(value, lot))
            }
        });
    };

    const handleDeleteDocument = (value) => {
        MySwal.fire({
            title: '¿Desea eliminar este documento?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7DABF5',
            confirmButtonColor: '#F87171',
            confirmButtonText: 'Eliminar',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteDocument(value, lot))
            }
        });
    };

    useEffect(() => {
        dispatch(getThumbnailLot(data?.lot))
    }, []);


    return (<Layout>
        <Helmet>
            <title>{lot}</title>
        </Helmet>
        <Modal isOpen={isOpen} close={openModal} children={content}/>
        <div className={"grid grid-cols-6 gap-4"}>
            <ButtonHelper handle={handleAdd}/>
            <div className={"w-full col-span-6 lg:col-span-4"}>
                <Plugins handleReport={handleViewReport} handleLabel={handleViewLabel} handleRegister={handleViewInfo}
                         handleProcess={handleViewProcess}/>
                <div className="w-full overflow-scroll scrollbar-hide gap-2 flex flex-col mb-2">
                    <TableLot lot={lot} handleDelete={handleDelete} handleUpdate={handleUpdate}/>
                </div>
                <Badge data={data ? data : []} items={items?items:[]}/>
                <CardsLot data={data ? data : []} items={items ? items : []}/>
            </div>
            <aside className={"w-full col-span-6  lg:col-span-2"}>
                <AsideLot handleUploadPhoto={handleAddPhotoProcess} handleUploadFile={handleAddFile}
                          files={files ? files : []} deleteDocument={handleDeleteDocument}
                          handleUpdateProduction={handleUpdateProduction}
                          deleteThumbnail={handleDeleteThumbnail} data={data ? data : []}
                          thumbnail={thumbnail ? thumbnail : []}/>
            </aside>

        </div>
    </Layout>);
};

export default Lot;
