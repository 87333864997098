import React, {useEffect} from 'react';
import TableBase, {Cell} from "../../util/Table";
import {getItemsLot} from "../../../redux/actions/logistic";
import {useDispatch, useSelector} from "react-redux";
import {PencilSquareIcon, TrashIcon} from "@heroicons/react/24/outline";
import Humanize from "humanize-plus";
import {GET_ITEMS_LOT_FAIL} from "../../../redux/actions/types";

const TableLot = ({lot, handleUpdate, handleDelete}) => {
    const pallets = useSelector(state => state.Logistic.pallets);

    const dispatch = useDispatch();
    const data = useSelector(state => state.Logistic.items);


    useEffect(() => {
        dispatch({type: GET_ITEMS_LOT_FAIL})
        if (lot)
        setTimeout(() => {
            dispatch(getItemsLot(lot))
        }, 500);

    }, []);

    const columns = ["", "N°", "Peso bruto", "Peso neto", "Tara", "Pallet", "Jabas","Greenbox"]
    return (<div>
        <TableBase columns={columns} cell={Row(data, handleUpdate, handleDelete,pallets)}/>
    </div>);
};


const Row = (data, update, remove, pallets) => (data && data.map((item, index) => {
    const getPallet = (id) => {
        const pallet = pallets && pallets.find(e => e.id === id);
        return pallet?.name
    }


    return (<tr key={index} className="h-max border-gray-300  border-b">
        <Cell text={<div className={"font-bold flex items-center whitespace-nowrap"}>
            <PencilSquareIcon onClick={() => update(item)} title={"Editar"}
                              className={"h-5 w-5 mr-2 text-blue-500 cursor-pointer"}/>
            <TrashIcon onClick={() => remove(item?.id)} title={"Eliminar"}
                       className={"h-5 w-5 mr-2 text-red-500 cursor-pointer"}/>
        </div>}/>

        <Cell text={item?.number}/>
        <Cell text={Humanize.formatNumber(item?.weight, 2)}/>
        <Cell text={<p
            className={"font-bold"}>{Humanize.formatNumber(parseFloat(item?.weight) - parseFloat(item?.tare), 2)}</p>}/>
        <Cell text={Humanize.formatNumber(item?.tare, 2)}/>
        <Cell text={getPallet(item?.pallet)}/>
        <Cell text={Humanize.formatNumber(
            0+parseInt(item?.box_0_25)+parseInt(item?.box_0_5)+parseInt(item?.box_1)+parseInt(item?.box_1_5)+parseInt(item?.box_1_6)
            , 0)}/>
        <Cell text={Humanize.formatNumber(item?.box_greenbox, 2)}/>
    </tr>)
}));

export default TableLot;
